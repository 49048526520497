/* eslint-disable dot-notation */
import { VerifierSingleCardWithTabsTemplate } from "../../../../templates";
import { Column, Row } from "../../../../widget";
import { DashboardPieCard } from "../../../shared/components";
import { ActivityReviewWorkflow } from "./components";
import { useActivityReviewPage } from "./useActivityReviewPage";

export const ActivityReviewPage = (): JSX.Element => {
  const {
    currentActivityReviewType,
    tabsList,
    handleBackClick,
    activityReviews,
    discussionsChartData,
    workflowActivityReviews,
    backButtonText,
    checkIsManagerUser,
    refreshActivityReviews,
    isLoading,
    setRefreshActivityReviews,
  } = useActivityReviewPage();

  const kpis = (): JSX.Element => {
    return (
      <Row>
        <Column span="7_half">
          <ActivityReviewWorkflow
            type={currentActivityReviewType}
            activityReviews={activityReviews}
            workflowActivityReviews={workflowActivityReviews}
            canManageActivityReview={checkIsManagerUser}
            refreshActivityReviews={refreshActivityReviews}
            setRefreshActivityReviews={setRefreshActivityReviews}
          />
        </Column>
        <Column span="4_half" style={{ paddingRight: 0 }}>
          <DashboardPieCard
            data={discussionsChartData}
            title="Discussions"
            colours="discussionsKPI"
            noDataMessage="No discussions"
            size={150}
            showValues={false}
          />
        </Column>
      </Row>
    );
  };

  if (!activityReviews?.results.length) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <VerifierSingleCardWithTabsTemplate
      activeElement="Projects"
      activityReviews={workflowActivityReviews}
      canManageActivityReview={checkIsManagerUser}
      pageTitle={`${
        activityReviews?.results[0].group?.displayName ?? activityReviews?.results[0].project?.displayName
      } ${activityReviews?.results[0].activity.activityDefinition.displayName ?? "Activity"}`}
      titlePill={activityReviews?.results[0].standard?.displayName}
      tabsList={tabsList}
      kpis={kpis()}
      isLoading={isLoading}
      backButtonText={backButtonText}
      handleBackClick={handleBackClick}
    />
  );
};

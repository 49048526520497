import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { PrivateProjectDocumentsParams } from "../../../../../../models";
import {
  getProjectDocumentVersion,
  GetProjectDocumentVersionResponse,
  searchProjectDocuments,
} from "../../../../../../service/query";
import { Status } from "../../../../../../service/Shared";
import { dataGridMapFilterCriteria } from "../../../../../../widget";
import { ProjectContext } from "../../ProjectContext";

interface DocumentProviderProps {
  children?: JSX.Element;
}

interface DocumentContextType {
  projectDocumentUuid?: string;
  latestDocumentVersion: GetProjectDocumentVersionResponse | undefined;
  refreshDocuments: boolean;
  setRefreshDocuments: Dispatch<SetStateAction<boolean>>;
}

export const DocumentContext = createContext<DocumentContextType>({} as DocumentContextType);

export const DocumentProvider = ({ children }: DocumentProviderProps): JSX.Element => {
  const { projectDocumentUuid } = useParams<PrivateProjectDocumentsParams.projectDocumentUuid>();

  const { projectDetails } = useContext(ProjectContext);

  const [isLoading, setIsLoading] = useState(true);
  const [refreshDocuments, setRefreshDocuments] = useState<boolean>(false);
  const [latestDocumentVersion, setLatestDocumentVersion] = useState<GetProjectDocumentVersionResponse>();

  const fetchData = useCallback(async () => {
    if (projectDocumentUuid) {
      const filterCriteria = dataGridMapFilterCriteria([]);

      filterCriteria.results = {
        uuid: {
          operator: "eq",
          value: projectDocumentUuid,
        },
      };

      const searchProjectDocumentsResponse = await searchProjectDocuments({
        projectUuid: projectDetails?.uuid || "",
        groupUuid: projectDetails?.group?.groupUuid || null,
        paging: { beforeCursor: null, afterCursor: null, limit: 50 },
        /* eslint-disable @typescript-eslint/no-explicit-any */
        filter: filterCriteria,
      });

      if (
        searchProjectDocumentsResponse.status === Status.Success &&
        searchProjectDocumentsResponse.data &&
        searchProjectDocumentsResponse.data.results.length > 0
      ) {
        const latestDocumentVersionUuid = searchProjectDocumentsResponse.data?.results[0].versionUuid;
        getProjectDocumentVersion({ projectDocumentHistoryUuid: latestDocumentVersionUuid }).then(
          (getProjectDocumentVersionResponse) => {
            setLatestDocumentVersion(getProjectDocumentVersionResponse.data);
          }
        );
      }
    }
  }, [projectDocumentUuid, projectDetails]);

  useEffect(() => {
    fetchData().finally(() => setIsLoading(false));
  }, [fetchData, projectDocumentUuid, projectDetails, refreshDocuments]);

  const memoisedValue = useMemo(
    () => ({
      projectDocumentUuid,
      latestDocumentVersion,
      refreshDocuments,
      setRefreshDocuments,
    }),
    [projectDocumentUuid, latestDocumentVersion, fetchData, refreshDocuments]
  );

  return <DocumentContext.Provider value={memoisedValue}>{!isLoading && children}</DocumentContext.Provider>;
};

import "./MapControl.css";

import { useState } from "react";

import { SelectData } from "../../../../models/shared";
import { FormLabel, Select, Toggle } from "../../../forms";

interface MapControlProps {
  is3d: boolean;
  onChange3dView: (value: boolean) => void;
  onChangeLayer: (value: string) => void;
  selectedLayer: MapLayerOptions;
  disableSelection?: boolean;
}

export enum MapLayerOptions {
  BASE = "Base Layer",
  VEGETATION = "Vegetation - Tree",
  FIRE_RISK = "Fire risk",
  TEMPERATURE = "Temperature",
  WATER_INDICES = "Water indices - WVWI scale",
  CARBON_STOCK = "Carbon stock monitoring",
}

export const MapControl = ({
  is3d,
  onChange3dView,
  onChangeLayer,
  selectedLayer,
  disableSelection,
}: MapControlProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = (): void => {
    setIsExpanded((prev) => !prev);
  };

  const selectData: SelectData = [
    { key: MapLayerOptions.BASE, value: MapLayerOptions.BASE },
    { key: MapLayerOptions.FIRE_RISK, value: MapLayerOptions.FIRE_RISK },
    { key: MapLayerOptions.TEMPERATURE, value: MapLayerOptions.TEMPERATURE },
    { key: MapLayerOptions.WATER_INDICES, value: MapLayerOptions.WATER_INDICES },
    { key: MapLayerOptions.VEGETATION, value: MapLayerOptions.VEGETATION },
    { key: MapLayerOptions.CARBON_STOCK, value: MapLayerOptions.CARBON_STOCK },
  ];

  return (
    <div className={`map-expandable-control ${isExpanded ? "expanded" : ""}`}>
      {/* Top-left icon to toggle options */}
      <button
        className="menu-toggle"
        onClick={toggleExpand}
        aria-expanded={isExpanded}
        aria-label="Toggle options menu"
      >
        <span className="menu-icon">☰</span>
      </button>

      {/* Options Panel */}
      {isExpanded && (
        <div className="control-panel">
          <div className="control-section">
            <div className="control-toggle-view">
              <FormLabel label="Views" labelAlignment="left">
                <Toggle label="Enable 3D terrain" onChange={onChange3dView} value={is3d} />
              </FormLabel>
            </div>
            <div className="control-divider" />
            <div className="control-selection-layers">
              <Select
                className="layer-select"
                labelAlignment="left"
                label="Layers"
                data={selectData}
                value={selectedLayer}
                onChange={onChangeLayer}
                disabled={disableSelection}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import "./Select.css";

import RcSelect, { Option } from "rc-select";
import { useEffect, useMemo, useState } from "react";

import { DropdownLightIcon } from "../../../assets";
import { SelectData } from "../../../models";
import { FormLabel } from "../FormLabel";

export interface SelectProps {
  label?: string;
  allowLabelDangerousHtml?: boolean;
  initialKey?: string | null;
  value?: string | null;
  placeholder?: string;
  className?: string;
  dropdownMatchSelectWidth?: boolean;
  data: SelectData;
  order?: string[];
  onChange: (value: string) => void;
  labelAlignment?: "left" | "right" | "top" | "bottom";
  disabled?: boolean;
}

export const Select = ({
  label,
  allowLabelDangerousHtml,
  initialKey,
  value,
  placeholder = "Please select",
  className,
  dropdownMatchSelectWidth,
  data,
  order,
  onChange,
  labelAlignment = "top",
  disabled,
}: SelectProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const onChangeInternal = (val: string): void => {
    setSelectedValue(val);
    onChange(val);
  };

  const orderedData = useMemo<SelectData>(() => {
    if (order) {
      const updatedData: SelectData = [];

      order?.forEach((ord) => {
        const option = data.find((d) => d.key === ord);

        if (option) {
          updatedData.push(option);
        }
      });

      return updatedData;
    }

    return data;
  }, [order, data]);

  const options = orderedData?.map((kvp) => (
    <Option key={kvp.key} value={kvp.key}>
      {kvp.value}
    </Option>
  ));

  return (
    <FormLabel label={label} labelAlignment={labelAlignment} allowDangerousHtml={allowLabelDangerousHtml}>
      <div className={`${className || ""} form_field`}>
        <RcSelect
          defaultValue={initialKey}
          value={selectedValue}
          suffixIcon={<DropdownLightIcon />}
          menuItemSelectedIcon={undefined}
          placeholder={placeholder || undefined}
          onChange={onChangeInternal}
          prefixCls="Select"
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          dropdownAlign={{ overflow: { adjustY: 0 } }}
          disabled={disabled}
        >
          {options}
        </RcSelect>
      </div>
    </FormLabel>
  );
};

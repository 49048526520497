import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";

import {
  ActivityReviewTypeConstants,
  OrganisationRoleConstants,
  ProjectActivityReviewsConstants,
  UserType,
} from "../../../constants";
import { CursorChangeProps, ResultType } from "../../../models";
import {
  getActivityReviewAggregate,
  GetActivityReviewAggregateResponse,
  SearchActivityReviewsResponse,
} from "../../../service/query";
import { searchActivityReviews } from "../../../service/query/QueryService.full";
import { ResultData } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";
import { flattenObject } from "../../../utils";
import { getActivityReviewDashboardTabRoute } from "../../../utils/routes";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  DataGridCursorDataLoadEventHandler,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
} from "../../../widget";
import {
  AvatarCellFormatter,
  RemainingTimeCellFormatter,
} from "../../../widget/data/DataGrid/components/CellFormatters";
import { assignReviewCellFormatter } from "./components";

interface UseDashboardReturnData {
  activityReviewAggregate?: GetActivityReviewAggregateResponse;
  tableColumnsQueue: DataGridColumnDefinition[];
  tableColumnsUnassignedActivities: DataGridColumnDefinition[];
  isLoading: boolean;
  isLoadingAssessmentQueue: boolean;
  isLoadingReviewQueue: boolean;
  isLoadingUnassignedActivities: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedActivityReview?: any;
  showAssignActivityModal: boolean;
  refreshDashboardTables: boolean;
  setRefreshDashboardTables: Dispatch<SetStateAction<boolean>>;
  setShowAssignActivityModal: Dispatch<SetStateAction<boolean>>;
  onAssessmentQueueDataLoad: DataGridCursorDataLoadEventHandler;
  onReviewQueueDataLoad: DataGridCursorDataLoadEventHandler;
  onUnassignedActivitiesDataLoad: DataGridCursorDataLoadEventHandler;
  defaultSortingCriteria: SortColumn[];
  currentUserType: UserType;
  currentUserRole?: string;
}

export const useDashboard = (): UseDashboardReturnData => {
  const { currentUserRole, currentUserType, user } = useAuth();

  const [activityReviewAggregate, setActivityReviewAggregate] = useState<GetActivityReviewAggregateResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAssessmentQueue, setIsLoadingAssessmentQueue] = useState(true);
  const [isLoadingReviewQueue, setIsLoadingReviewQueue] = useState(true);
  const [isLoadingUnassignedActivities, setIsLoadingUnassignedActivities] = useState(true);
  const [refreshDashboardTables, setRefreshDashboardTables] = useState(false);

  const [showAssignActivityModal, setShowAssignActivityModal] = useState(false);
  const [selectedActivityReview, setSelectedActivityReview] = useState<unknown>();

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "calculatedDaysInQueue", direction: "DESC" }];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tableColumnsQueue: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    {
      key: "projectLink",
      name: "Project / Group",
      dataType: "string",
      formatter: "link",
      minWidth: 120,
      filterable: false,
      sortable: false,
    },
    {
      key: "developer.displayName",
      name: "Developer",
      dataType: "string",
      formatter: "stringWithEllipsis",
      minWidth: 90,
      filterable: false,
      sortable: false,
    },
    {
      key: "standard.displayName",
      name: "Code",
      dataType: "string",
      formatter: "stringWithEllipsis",
      filterable: false,
      sortable: false,
    },
    {
      key: "activity.activityDefinition.displayName",
      name: "Activity",
      dataType: "string",
      formatter: "stringWithEllipsis",
      filterable: false,
      sortable: false,
    },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectActivityReviewsStatusPill",
      minWidth: 180,
      filterable: false,
      sortable: false,
    },
    {
      key: "avatar",
      name: "Assigned to",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      customCellFormatter: AvatarCellFormatter,
      filterable: false,
      sortable: false,
    },
    {
      name: "Progress",
      key: "completionPercentage",
      dataType: "number",
      formatter: "progress",
      minWidth: 140,
      filterable: false,
      sortable: false,
    },
    {
      name: "Days in queue",
      key: "calculatedDaysInQueue",
      dataType: "number",
      minWidth: 120,
      filterable: false,
      sortable: true,
    },
    {
      name: "Deadline",
      key: "deadline",
      dataType: "number",
      formatter: "custom",
      customCellFormatter: RemainingTimeCellFormatter,
      minWidth: 160,
      filterable: false,
      sortable: false,
    },
  ];

  const tableColumnsUnassignedActivities:
    | DataGridColumnDefinition
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | DataGridColumnDefinitionWithCustomCellFormatter<any>[] = [
    {
      key: "projectLink",
      name: "Project / Group",
      dataType: "string",
      formatter: "link",
      filterable: false,
      sortable: false,
      minWidth: 120,
    },
    {
      key: "developer.displayName",
      name: "Developer",
      dataType: "string",
      minWidth: 90,
      filterable: false,
      sortable: false,
    },
    { key: "standard.displayName", name: "Code", dataType: "string", filterable: false, sortable: false },
    {
      key: "activity.activityDefinition.displayName",
      name: "Activity",
      dataType: "string",
      filterable: false,
      sortable: false,
    },
    {
      name: "Activity status",
      key: "activity.status",
      dataType: "string",
      formatter: "projectActivitiesStatusPill",
      minWidth: 240,
      filterable: false,
      sortable: false,
    },
    {
      name: "Days in queue",
      key: "calculatedDaysInQueue",
      dataType: "number",
      minWidth: 120,
      filterable: false,
      sortable: true,
    },
    {
      name: "Assign",
      key: "assign",
      dataType: "string",
      formatter: "custom",
      filterable: false,
      sortable: false,
      minWidth: 110,
      customCellFormatter: assignReviewCellFormatter,
    },
  ];

  const fetchData = useCallback(async () => {
    await getActivityReviewAggregate({
      activityReviewUuid: null,
      activityUuid: null,
      assignedToUserUuid: currentUserRole === OrganisationRoleConstants.VVB_AUDITOR ? (user?.userUuid ?? null) : null,
    }).then((response) => {
      setActivityReviewAggregate(response.data);
    });

    const filterCriteria = dataGridMapFilterCriteria([]);
    if (currentUserRole === OrganisationRoleConstants.VVB_AUDITOR) {
      filterCriteria.assignedToUser = {
        uuid: {
          operator: "eq",
          value: user?.userUuid,
        },
      };
    }

    filterCriteria.status = {
      operator: "neq",
      value: ProjectActivityReviewsConstants.STATUS_CANCELLED,
    };

    return searchActivityReviews({
      filter: { results: filterCriteria },
      paging: {
        afterCursor: null,
        beforeCursor: null,
        limit: 10,
      },
    });
  }, []);

  useEffect(() => {
    fetchData().finally(() => {
      setIsLoading(false);
    });
  }, [fetchData]);

  // We need to use any type here as we also assign avatar obj
  const formatQueueData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (responseData: any | undefined): ResultData[] =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      responseData?.results?.map((el: any) => {
        const result = flattenObject(el);
        const fullName: string = el.primaryAssignedToUser?.fullName ?? undefined;

        result.projectLink = {
          text: el.group?.displayName ?? el.project?.displayName,
          to: getActivityReviewDashboardTabRoute(
            el.activity.uuid,
            "documents",
            currentUserRole === OrganisationRoleConstants.VVB_AUDITOR ? `type=${el.type}` : undefined
          ),
        } as DataGridLinkCellFormatterData;

        return {
          ...result,
          avatar: { fullName },
          deadline: {
            days: el.calculatedDaysToDeadline,
            isComplete: el.status === ProjectActivityReviewsConstants.STATUS_COMPLETE,
          },
        };
      }) || [],
    []
  );

  const onAssessmentQueueDataLoad: DataGridCursorDataLoadEventHandler = async ({
    filtering,
    paging,
    sorting,
  }: CursorChangeProps) => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 5,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.type = {
      operator: "eq",
      value: ActivityReviewTypeConstants.ASSESSMENT,
    };

    filterCriteria.status = {
      operator: "neq",
      value: ProjectActivityReviewsConstants.STATUS_CANCELLED,
    };

    if (currentUserRole === OrganisationRoleConstants.VVB_AUDITOR) {
      filterCriteria.assignedToUser = {
        uuid: {
          operator: "eq",
          value: user?.userUuid,
        },
      };
    } else {
      filterCriteria.assignedToUser = {
        uuid: {
          operator: "neq",
          value: null,
        },
      };
    }

    setIsLoadingAssessmentQueue(true);

    await searchActivityReviews({
      filter: { results: filterCriteria },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
    })
      .then((response) => {
        data = {
          resultData: formatQueueData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            hasNextPage: false,
            hasPreviousPage: false,
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || response.data?.results?.length || 0,
          },
        };
      })
      .finally(() => {
        setIsLoadingAssessmentQueue(false);
      });
    return data;
  };

  const onReviewQueueDataLoad: DataGridCursorDataLoadEventHandler = async ({
    filtering,
    paging,
    sorting,
  }: CursorChangeProps) => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 5,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.type = {
      operator: "eq",
      value: ActivityReviewTypeConstants.REVIEW,
    };

    filterCriteria.status = {
      operator: "neq",
      value: ProjectActivityReviewsConstants.STATUS_CANCELLED,
    };

    if (currentUserRole === OrganisationRoleConstants.VVB_AUDITOR) {
      filterCriteria.assignedToUser = {
        uuid: {
          operator: "eq",
          value: user?.userUuid,
        },
      };
    } else {
      filterCriteria.assignedToUser = {
        uuid: {
          operator: "neq",
          value: null,
        },
      };
    }

    setIsLoadingAssessmentQueue(true);

    await searchActivityReviews({
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
    })
      .then((response) => {
        data = {
          resultData: formatQueueData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            hasNextPage: false,
            hasPreviousPage: false,
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || response.data?.results?.length || 0,
          },
        };
      })
      .finally(() => {
        setIsLoadingReviewQueue(false);
      });
    return data;
  };

  const formatUnassignedActivitiesData = useCallback(
    (responseData: SearchActivityReviewsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.projectLink = {
          text: el.group?.displayName ?? el.project?.displayName,
          to: getActivityReviewDashboardTabRoute(el.activity.uuid, "documents"),
        } as DataGridLinkCellFormatterData;

        result.assign = {
          action: () => {
            setSelectedActivityReview(el);
            setShowAssignActivityModal(true);
          },
          text: "Assign review",
        } as DataGridButtonLinkCellFormatterData;

        return result;
      }) || [],
    []
  );

  const onUnassignedActivitiesDataLoad: DataGridCursorDataLoadEventHandler = async ({
    paging,
    filtering,
    sorting,
  }: CursorChangeProps) => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 5,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.status = {
      operator: "neq",
      value: ProjectActivityReviewsConstants.STATUS_CANCELLED,
    };

    filterCriteria.assignedToUser = {
      uuid: {
        operator: "eq",
        value: null,
      },
    };

    setIsLoadingUnassignedActivities(true);

    await searchActivityReviews({
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
    })
      .then((response) => {
        data = {
          resultData: formatUnassignedActivitiesData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            hasNextPage: false,
            hasPreviousPage: false,
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || response.data?.results?.length || 0,
          },
        };
      })
      .finally(() => {
        setIsLoadingUnassignedActivities(false);
      });
    return data;
  };

  return {
    activityReviewAggregate,
    tableColumnsQueue,
    tableColumnsUnassignedActivities,
    isLoading,
    selectedActivityReview,
    showAssignActivityModal,
    refreshDashboardTables,
    setRefreshDashboardTables,
    setShowAssignActivityModal,
    onAssessmentQueueDataLoad,
    onReviewQueueDataLoad,
    onUnassignedActivitiesDataLoad,
    isLoadingAssessmentQueue,
    isLoadingReviewQueue,
    isLoadingUnassignedActivities,
    currentUserType,
    currentUserRole,
    defaultSortingCriteria,
  };
};

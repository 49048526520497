import { Dispatch, SetStateAction, useContext, useState } from "react";

import { LocationData } from "../../../../../models";
import { GetProjectDetailsResponse } from "../../../../../service/query";
import { ProjectContext } from "../ProjectContext";

interface UseLocationReturnData {
  projectDetails: GetProjectDetailsResponse | undefined;
  locationData: LocationData;
  setLocationData: Dispatch<SetStateAction<LocationData>>;
}

export const useLocation = (): UseLocationReturnData => {
  const { projectDetails } = useContext(ProjectContext);
  const [locationData, setLocationData] = useState<LocationData>({
    addressCountryCode: projectDetails?.addressCountryCode,
    gridReference: projectDetails?.locationGridReference,
    latitude: "55.574",
    longitude: "-2.948",
  });

  return {
    projectDetails,
    locationData,
    setLocationData,
  };
};

import "../styles/DataGrid.css";

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Column as RdgColumn, SortColumn as RdgSortColumn } from "react-data-grid";

import { FilterCriteria, FilterOperator, SortCriteria } from "../../../../models";
import { ResultData } from "../../../../service/Shared";
import { onFilterChangedEvent } from "../components/HeaderRenderer";
import { getFilterCriteriaWithCastedValues, mapColumnDefinitionToRdgColumn } from "../utils";
import { CursorDataGridProps } from "./CursorDataGrid";

export interface UseDataGridReturnData {
  filterCriteria: FilterCriteria[];
  setFilterCriteria: Dispatch<SetStateAction<FilterCriteria[]>>;
  resultData: readonly ResultData[];
  rdgColumns: RdgColumn<ResultData>[];
  sortColumns: readonly RdgSortColumn[];
  onSort: (newSortColumns: RdgSortColumn[]) => void;
  onPrevious: () => void;
  onNext: () => void;
  hasPrevious: boolean;
  hasNext: boolean;
  totalCount: number;
  filtersEnabled: boolean;
  setFiltersEnabled: Dispatch<SetStateAction<boolean>>;
  showFilterToggleSwitch: boolean;
  onExport: () => void;
}

export const useCursorDataGrid = ({
  data,
  columns,
  filterable = false,
  sortable = true,
  onChange,
  pagination = { pageSize: 10 },
  defaultSortingCriteria = [],
  refresh,
  showFilterToggle,
  triggerPagePrevious,
  exportFileName = "",
}: CursorDataGridProps): UseDataGridReturnData => {
  const [resultData, setResultData] = useState<readonly ResultData[]>(data || []);
  const [sortColumns, setSortColumns] = useState<readonly RdgSortColumn[]>(
    sortable && defaultSortingCriteria ? defaultSortingCriteria : []
  );

  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria[]>([]);
  const [dgStartCursor, setDgStartCursor] = useState<string | undefined>(pagination.startCursor);
  const [dgEndCursor, setDgEndCursor] = useState<string | undefined>(pagination.endCursor);
  const [dgBeforeCursor, setDgBeforeCursor] = useState<string>();
  const [dgAfterCursor, setDgAfterCursor] = useState<string>();
  const [hasNext, setHasNext] = useState<boolean>(true);
  const [hasPrevious, setHasPrevious] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [filtersEnabled, setFiltersEnabled] = useState(false);

  const onPrevious = (): void => {
    setDgBeforeCursor(dgStartCursor);
    setDgAfterCursor(undefined);
  };

  useEffect(() => {
    if (triggerPagePrevious !== undefined && hasPrevious) {
      onPrevious();
    }
  }, [triggerPagePrevious]);

  const onNext = (): void => {
    setDgBeforeCursor(undefined);
    setDgAfterCursor(dgEndCursor);
  };

  const onSort = (newSortColumns: RdgSortColumn[]): void => {
    setSortColumns(newSortColumns);
    // reset paging when changing sort order
    setDgBeforeCursor(undefined);
    setDgAfterCursor(undefined);
  };

  const pageSize = pagination.pageSize || 10;

  const onFilterChanged: onFilterChangedEvent = (
    columnKey: string,
    operator: FilterOperator,
    value: string,
    filterId: number | undefined
  ): void => {
    let starterFilters = filterCriteria.filter((f) => f.key !== columnKey);

    starterFilters = starterFilters.concat({
      key: columnKey,
      operator,
      value,
      filterId,
    });

    setFilterCriteria(starterFilters);
    // reset paging when changing filtering
    setDgBeforeCursor(undefined);
    setDgAfterCursor(undefined);
  };

  const rdgColumns: RdgColumn<ResultData>[] = mapColumnDefinitionToRdgColumn(
    columns,
    sortable,
    filterable,
    sortColumns,
    setSortColumns,
    filtersEnabled,
    filterCriteria,
    setFilterCriteria,
    onFilterChanged
  );

  const getCurrentFilterCriteria = (criteria: FilterCriteria[]): FilterCriteria[] => {
    let tempFilterCriteria = [...criteria];

    if (criteria.some((fc) => fc.value === "" && fc.operator !== "contains")) {
      // If value is empty, remove that filter from a temp Array so that all data is being refetched
      tempFilterCriteria = tempFilterCriteria.filter((tfc) => !(tfc.value === "" && tfc.operator !== "contains"));
    }
    return tempFilterCriteria;
  };

  const getCurrentSortCriteria = (sortCols: readonly RdgSortColumn[]): SortCriteria[] => {
    return sortCols.map(
      (sc): SortCriteria => ({
        key: sc.columnKey,
        direction: sc.direction === "DESC" ? "desc" : "asc",
      })
    );
  };

  if (onChange) {
    useEffect(() => {
      const tempFilterCriteria = getCurrentFilterCriteria(filterCriteria);

      const sortCriteria = getCurrentSortCriteria(sortColumns);

      onChange({
        filtering: getFilterCriteriaWithCastedValues(tempFilterCriteria, columns),
        sorting: sortCriteria,
        paging: {
          pageSize,
          afterCursor: dgAfterCursor,
          beforeCursor: dgBeforeCursor,
        },
      }).then((update) => {
        setResultData(update.resultData);
        if (update.paging?.startCursor) setDgStartCursor(update.paging.startCursor);
        if (update.paging?.endCursor) setDgEndCursor(update.paging.endCursor);
        setHasPrevious(update.paging.hasPreviousPage);
        setHasNext(update.paging.hasNextPage);
        setTotalCount(update.paging.totalCount);
      });
    }, [sortColumns, filterCriteria, dgBeforeCursor, dgAfterCursor, refresh]);
  }

  const onExport = (): void => {
    if (onChange) {
      let csvURL: string;

      const tempFilterCriteria = getCurrentFilterCriteria(filterCriteria);
      const sortCriteria = getCurrentSortCriteria(sortColumns);

      onChange({
        filtering: getFilterCriteriaWithCastedValues(tempFilterCriteria, columns),
        sorting: sortCriteria,
        paging: {
          pageSize: totalCount > 1000 ? 1000 : totalCount,
        },
      })
        .then((update) => {
          const separator = ",";
          const header = `${rdgColumns.map((x) => x.name).join(separator)}\n`;
          const csv =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line prettier/prettier
          header + update.resultData.map((row) => rdgColumns.map((col) => row[col.key]?.text?? row[col.key]).join(separator)).join("\n");
          const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          csvURL = window.URL.createObjectURL(csvData);
          const tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute("download", `${exportFileName}${new Date().toISOString()}_grid_data.csv`);
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
        })
        .finally(() => {
          URL.revokeObjectURL(csvURL);
        });
    }
  };

  return {
    resultData,
    filterCriteria,
    setFilterCriteria,
    onPrevious,
    onNext,
    hasPrevious,
    hasNext,
    rdgColumns,
    onSort,
    sortColumns,
    totalCount,
    filtersEnabled,
    setFiltersEnabled,
    showFilterToggleSwitch: showFilterToggle ?? filterable,
    onExport,
  };
};

import { ProjectActivitiesConstants } from "../../constants";
import { GetCurrentUserProjectPermissionsResponse } from "../../service/query";

export const hasActivityPermissionForProject = (
  permissions: GetCurrentUserProjectPermissionsResponse[],
  projectUuid: string | undefined | null,
  activityPermission: string,
  groupUuid?: string | null
): boolean => {
  return projectUuid
    ? (permissions.find((p) => p.projectUuid === projectUuid)?.currentUserPermissions.includes(activityPermission) ??
        false)
    : (permissions.find((p) => p.groupUuid === groupUuid)?.currentUserPermissions.includes(activityPermission) ??
        false);
};
export const checkCanManageActivity = (
  hasManageProjectActivityPermission: boolean,
  activityStatus?: string
): boolean => {
  return (
    hasManageProjectActivityPermission &&
    activityStatus !== ProjectActivitiesConstants.STATUS_APPROVED &&
    activityStatus !== ProjectActivitiesConstants.STATUS_SUPERSEDED &&
    activityStatus !== ProjectActivitiesConstants.STATUS_DELETED
  );
};

import "./MapBox.css";

import mapboxgl from "mapbox-gl";
import { useEffect, useRef } from "react";

interface MapBoxProps {
  coordinate: { lat: number; lng: number };
  is3d?: boolean;
  projectShape: { shapeFileUrl: string; fillColor: string };
}

export const MapBox = ({ coordinate, is3d, projectShape }: MapBoxProps): JSX.Element => {
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const MAPBOX_TOKEN = "pk.eyJ1Ijoiamlhbm5hbmx1IiwiYSI6ImNtM3BvdjNpdjBlN3oycXM0eXdmcWRxODkifQ._puZrLst8YIgoDn2sye1tQ";

  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_TOKEN;
    const map = new mapboxgl.Map({
      container: mapContainerRef.current as HTMLElement,
      zoom: 14,
      center: new mapboxgl.LngLat(coordinate.lng, coordinate.lat),

      style: "mapbox://styles/mapbox/satellite-streets-v12",
    });

    map.on("style.load", () => {
      map.addSource("shapefile", {
        type: "geojson",
        data: projectShape.shapeFileUrl,
      });
      map.addLayer({
        id: "shapefile",
        type: "fill",
        source: "shapefile",
        paint: {
          "fill-color": projectShape.fillColor,
          "fill-opacity": 0.2,
        },
      });
    });
    if (is3d) {
      map.setBearing(41);
      map.setPitch(80);
      map.on("style.load", () => {
        map.addSource("mapbox-dem", {
          type: "raster-dem",
          url: "mapbox://mapbox.mapbox-terrain-dem-v1",
          tileSize: 512,
          maxzoom: 14,
        });
        map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
      });
    }

    mapRef.current = map;
    return () => mapRef.current?.remove();

    // return () => {
    //   mapRef.current?.remove();
    // };
  }, [coordinate]);

  return <div id="mapbox-container" ref={mapContainerRef} />;
};

import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { OrganisationTypeConstants, ProjectPermissionConstants } from "../../../../../../constants";
import {
  CursorChangeProps,
  LayeredSelectData,
  Origin,
  PrivateProjectDocumentsParams,
  ResultType,
  SelectData,
} from "../../../../../../models";
import { downloadProjectDocument, updateProjectDocument } from "../../../../../../service/project";
import {
  GetProjectDocumentVersionResponse,
  searchProjectDocumentHistory,
  SearchProjectDocumentHistoryResponse,
  searchProjectDocumentTypeVariants,
} from "../../../../../../service/query";
import { ResultData, Status } from "../../../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../../../useAuth";
import { flattenObject, initiateDocumentDownloadViaBrowser, useIsLoadingWrapper } from "../../../../../../utils";
import { getProjectDocumentsRoute, getSettingsAuditHistoryRoute } from "../../../../../../utils/routes";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
  Toast,
} from "../../../../../../widget";
import { downloadDocumentCellFormatter } from "../../../../../developer/activities";
import { ProjectContext } from "../../ProjectContext";
import { deleteDocumentVersionCellFormatter, editDocumentVersionCellFormatter } from "./components";
import { DocumentContext } from "./DocumentContext";

interface UseDocumentHistoryReturnData {
  projectDisplayName?: string;
  documentVariants?: LayeredSelectData;
  customVariant?: string;
  selectedDocumentVariant?: string;
  hasManageProjectDocumentPermission: boolean;
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  selectedDocumentUuid?: string;
  projectDocumentUuid?: string;
  latestDocumentVersion?: GetProjectDocumentVersionResponse;
  refreshDocuments: boolean;
  isUpdateDocumentLoading: boolean;
  showDeletePublicModal: boolean;
  setShowDeletePublicModal: Dispatch<SetStateAction<boolean>>;
  showDeleteModal: boolean;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  showEditModal: boolean;
  setShowEditModal: Dispatch<SetStateAction<boolean>>;
  showUploadModal: boolean;
  setShowUploadModal: Dispatch<SetStateAction<boolean>>;
  onChangeDocumentVariant: (value: string) => void;
  setCustomVariant: Dispatch<SetStateAction<string | undefined>>;
  setRefreshDocuments: Dispatch<SetStateAction<boolean>>;
  updateDocument: () => Promise<void>;
  handleBackClick: () => void;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  backBtnText: string;
}

export const useDocumentHistory = (): UseDocumentHistoryReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();
  const { projectUuid, projectDocumentUuid } = useParams<PrivateProjectDocumentsParams>();

  const { projectDetails, hasProjectPermission } = useContext(ProjectContext);
  const { latestDocumentVersion, refreshDocuments, setRefreshDocuments } = useContext(DocumentContext);

  const [documentVariants, setDocumentVariants] = useState<LayeredSelectData>();
  const [selectedDocumentVariant, setSelectedDocumentVariant] = useState<string>();
  const [customVariant, setCustomVariant] = useState<string>();
  const [documentRowVersion, setDocumentRowVersion] = useState(1);
  const [selectedDocumentUuid, setSelectedDocumentUuid] = useState<string>();

  const [showDeletePublicModal, setShowDeletePublicModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isUpdateDocumentLoading, setIsUpdateDocumentLoading] = useState(false);

  const [dataIsLoading, setDataIsLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin");
  const backButtonText =
    origin === Origin.ProjectAuditPage || origin === Origin.OrganisationAuditPage
      ? "Back to audit"
      : "Back to full project";

  const hasManageProjectDocumentPermission = hasProjectPermission(ProjectPermissionConstants.MANAGE_PROJECT_DOCUMENT);

  const handleBackClick = (): void => {
    if (origin === Origin.OrganisationAuditPage) navigate(getSettingsAuditHistoryRoute(currentUserType));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    else navigate(getProjectDocumentsRoute(projectUuid!, currentUserType));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    {
      key: "projectDocument.version",
      name: "Version",
      dataType: "string",
      minWidth: 90,
    },
    {
      key: "projectDocument.file.filename",
      name: "File name",
      dataType: "string",
      formatter: "stringWithEllipsis",
      filterable: true,
      sortable: true,
      minWidth: 200,
    },
    {
      key: "projectDocument.visibility",
      name: "Visibility",
      dataType: "string",
      minWidth: 120,
    },
    {
      key: "projectDocument.externalParty",
      name: "Source",
      dataType: "string",
      filterable: false,
      minWidth: 120,
    },
    {
      key: "projectDocument.createdAt",
      name: "Date uploaded",
      dataType: "Date",
      formatter: "dateOnly",
      filterable: false,
      minWidth: 120,
    },
    {
      key: "projectDocument.createdByUser.fullName",
      name: "Uploaded by",
      dataType: "string",
      formatter: "stringWithEllipsis",
      minWidth: 120,
    },
    {
      key: "downloadIcon",
      name: "Download",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      filterable: false,
      sortable: false,
      minWidth: 90,
      customCellFormatter: downloadDocumentCellFormatter,
    },
  ];

  if (currentUserType === OrganisationTypeConstants.DEVELOPER)
    columns.push(
      {
        key: "editIcon",
        name: "Edit",
        dataType: "string",
        formatter: "custom",
        alignment: "center",
        filterable: false,
        sortable: false,
        minWidth: 90,
        customCellFormatter: editDocumentVersionCellFormatter,
      },
      {
        key: "deleteIcon",
        name: "Delete",
        dataType: "string",
        formatter: "custom",
        alignment: "center",
        filterable: false,
        sortable: false,
        minWidth: 90,
        customCellFormatter: deleteDocumentVersionCellFormatter,
      }
    );

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "projectDocument.version", direction: "DESC" }];

  const onChangeDocumentVariant = (value: string): void => {
    setSelectedDocumentVariant(value);
    if (value === "Custom") {
      setCustomVariant(latestDocumentVersion?.customVariant || undefined);
    } else {
      setCustomVariant(undefined);
    }
  };

  const updateDocument = useIsLoadingWrapper(async (): Promise<void> => {
    const variantUuid =
      documentVariants && documentVariants[0].options.find((v) => v.value === selectedDocumentVariant)?.key;

    if (projectDocumentUuid) {
      await updateProjectDocument({
        projectDocumentUuid,
        variantUuid: variantUuid?.toString() || null,
        customVariant: customVariant || null,
        rowVersion: documentRowVersion,
      }).then((response) => {
        if (response.status === Status.Success) {
          Toast.success({ message: "Document successfully updated" });

          setDocumentRowVersion(response.data?.rowVersion || documentRowVersion + 1);
        }
        if (response.status === Status.Error && response.errors && response.errors.length) {
          Toast.error({ message: getErrorMessageFromCode(response.errors[0].code) });
        }
      });
    }
  }, setIsUpdateDocumentLoading);

  const formatData = useCallback(
    (responseData: SearchProjectDocumentHistoryResponse | undefined): ResultData[] => {
      return (
        responseData?.results?.map((d) => {
          const result = flattenObject(d);

          result["projectDocument.externalParty"] =
            d.projectDocument.externalParty !== null ? d.projectDocument.externalParty : "User upload";

          result.downloadIcon = <DataGridButtonLinkCellFormatterData>{
            action: async () => {
              const res = await downloadProjectDocument({ projectDocumentHistoryUuid: d.projectDocument.uuid });
              initiateDocumentDownloadViaBrowser(res);
            },
            text: "Download",
          };

          if (
            hasManageProjectDocumentPermission &&
            (!d.projectDocument.standardApproved ||
              (d.projectDocument.standardApproved && d.projectDocument.visibility === "Private"))
          )
            result.editIcon = {
              action: () => {
                setSelectedDocumentUuid(d.projectDocument.uuid);
                setShowEditModal(true);
              },
              text: "Edit",
            } as DataGridButtonLinkCellFormatterData;

          if (!d.projectDocument.standardApproved && hasManageProjectDocumentPermission)
            result.deleteIcon = {
              action: () => {
                if (
                  d.projectDocument.visibility === "Public" &&
                  responseData?.results.find((r) => r.projectDocument.standardApproved)
                ) {
                  setShowDeletePublicModal(true);
                } else {
                  setSelectedDocumentUuid(d.projectDocument.uuid);
                  setShowDeleteModal(true);
                }
              },
              text: "Delete",
            } as DataGridButtonLinkCellFormatterData;
          return result;
        }) || []
      );
    },
    [refreshDocuments]
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    if (!projectUuid) throw new Error("Uuid must not be empty!");
    if (!projectDocumentUuid) throw new Error("Document uuid must not be empty!");

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    await searchProjectDocumentHistory({
      projectDocumentUuid,
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: dataGridMapFilterCriteria(filtering) },
    })
      .then((response) => {
        // Navigate back if last document deleted
        if (response.data === undefined || response.data?.results.length === 0) {
          handleBackClick();
          return;
        }
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });
    return data;
  };

  const setVariantsCallback = useCallback(async (): Promise<void> => {
    if (latestDocumentVersion !== undefined) {
      const filterCriteria = dataGridMapFilterCriteria([]);

      filterCriteria.results = {
        typeUuid: {
          operator: "eq",
          value: latestDocumentVersion?.documentType.uuid || "",
        },
      };

      await searchProjectDocumentTypeVariants({
        paging: { beforeCursor: null, afterCursor: null, limit: 50 },
        filter: filterCriteria,
      }).then((response) => {
        setDocumentVariants(
          response.data?.results && response.data?.results.length > 0
            ? [
                {
                  optionsHeader: latestDocumentVersion?.documentType.name,
                  options: response.data?.results.map((v) => ({ key: v.uuid, value: v.name })) as SelectData,
                },
                {
                  optionsHeader: { key: "Custom", value: "Custom" },
                  options: [],
                },
                {
                  optionsHeader: { key: "N/A", value: "N/A" },
                  options: [],
                },
              ]
            : [
                {
                  optionsHeader: { key: "Custom", value: "Custom" },
                  options: [],
                },
                {
                  optionsHeader: { key: "N/A", value: "N/A" },
                  options: [],
                },
              ]
        );
      });
    }
  }, [latestDocumentVersion]);

  const getDocumentDetails = useCallback(async (): Promise<void> => {
    if (latestDocumentVersion) {
      setSelectedDocumentVariant(
        // eslint-disable-next-line no-nested-ternary
        latestDocumentVersion.documentVariant?.name != null
          ? latestDocumentVersion.documentVariant?.name
          : latestDocumentVersion.customVariant != null
            ? "Custom"
            : undefined
      );
      setCustomVariant(latestDocumentVersion.customVariant || undefined);
      setDocumentRowVersion(latestDocumentVersion.documentRowVersion || documentRowVersion);
      if (latestDocumentVersion?.documentType.supportsVariants) {
        setVariantsCallback();
      }
    }
  }, [latestDocumentVersion]);

  useEffect(() => {
    getDocumentDetails();
  }, [latestDocumentVersion]);

  return {
    projectDisplayName: projectDetails?.displayName,
    documentVariants,
    customVariant,
    selectedDocumentVariant,
    hasManageProjectDocumentPermission,
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    selectedDocumentUuid,
    projectDocumentUuid,
    latestDocumentVersion,
    refreshDocuments,
    isUpdateDocumentLoading,
    setRefreshDocuments,
    showDeletePublicModal,
    setShowDeletePublicModal,
    showDeleteModal,
    setShowDeleteModal,
    showEditModal,
    setShowEditModal,
    showUploadModal,
    onChangeDocumentVariant,
    setCustomVariant,
    setShowUploadModal,
    handleBackClick,
    updateDocument,
    onChange,
    backBtnText: backButtonText,
  };
};

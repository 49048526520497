/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "./Location.css";

import React, { useState } from "react";

// eslint-disable-next-line unused-imports/no-unused-imports
import {
  Column,
  MapBox,
  MapControl,
  MapLayerOptions,
  MapOpenLayer,
  MapOpenLayerGisFileProps,
  Row,
} from "../../../../../widget";
import { useLocation } from "./useLocation";

const Location = (): JSX.Element => {
  const { locationData } = useLocation();
  const [is3d, setIs3d] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState(MapLayerOptions.BASE);

  const [gisFile, setGisFile] = useState<MapOpenLayerGisFileProps>({
    projectShape: {
      geoJsonFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/Broadmeadows.json",
      fillColor: "#FF865D",
    },
    vectorFile: undefined,
    rasterFile: undefined,
  });

  const changeSelectedLayer = (newSelectedLayer: MapLayerOptions): void => {
    gisFile.rasterFile = undefined;
    gisFile.vectorFile = undefined;
    switch (newSelectedLayer) {
      case MapLayerOptions.VEGETATION:
        gisFile.rasterFile = {
          geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/vegetation.tif",
          fillColor1: "#968735",
          fillColor1Text: "Tree",
          fillColor1Opacity: "1",
          fillColor2: "#FFFFFF",
          fillColor2Text: "None",
          middleColor: "#78BB96",
          middleColorText: "Vegetation",
          minValue: 3,
          maxValue: 5,
        };
        break;
      case MapLayerOptions.FIRE_RISK:
        gisFile.rasterFile = {
          geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/20241219_20241219.tif",
          fillColor1: "#FBE9EA",
          fillColor2: "#711016",
          minValue: 0,
          maxValue: 1,
        };
        break;
      case MapLayerOptions.WATER_INDICES:
        gisFile.rasterFile = {
          geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/water_indices.tif",
          fillColor1: "#E1F3F8",
          fillColor2: "#396877",
          minValue: -1,
          maxValue: 1,
        };
        break;
      case MapLayerOptions.TEMPERATURE:
        gisFile.vectorFile = {
          geoJsonFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/npower_1y.json",
          featurePropertyName: "temperature",
          fillColor1: "#28245C",
          fillColor2: "#D71F2A",
          middleColor: "#FFFFFF",
          isDateFilterRequired: true,
        };
        break;
      case MapLayerOptions.CARBON_STOCK:
        gisFile.vectorFile = {
          geoJsonFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/tree_carbon.json",
          featurePropertyName: "count",
          fillColor1: "#5bff33",
          fillColor2: "#1e241d",
        };
        break;
      default:
        gisFile.rasterFile = undefined;
        gisFile.vectorFile = undefined;
    }
    setGisFile(gisFile);
    setSelectedLayer(newSelectedLayer);
  };
  // useEffect(() => {}, [selectedLayer]);

  return (
    <div className="ProjectTabSection">
      <Row spacingV="2xl">
        <Column span={12}>
          <h2>Location</h2>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <div className="map-container">
            <MapControl
              is3d={is3d}
              onChange3dView={(e1) => {
                setIs3d(e1);
                if (e1) {
                  changeSelectedLayer(MapLayerOptions.BASE);
                }
              }}
              onChangeLayer={(e2) => {
                changeSelectedLayer(e2 as MapLayerOptions);
              }}
              disableSelection={selectedLayer === MapLayerOptions.BASE && is3d}
              selectedLayer={selectedLayer}
            />
            {is3d === true ? (
              <MapBox
                projectShape={{
                  shapeFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/Broadmeadows.json",
                  fillColor: "#FF865D",
                }}
                coordinate={{ lat: Number(locationData.latitude!), lng: Number(locationData.longitude!) }}
                is3d={is3d}
              />
            ) : (
              <MapOpenLayer
                coordinate={{ lat: Number(locationData.latitude!), lng: Number(locationData.longitude!) }}
                gisFile={gisFile}
              />
            )}
          </div>
        </Column>
      </Row>
    </div>
  );
};

export const LocationTab = React.memo(Location);

/* eslint no-console:0 */
import "./MultipleFileUpload.css";

import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import { forwardRef } from "react";

import { DownloadDarkIcon, EyeGreyIcon, FileBlueIcon, FilePlusBlueIcon, TrashDark } from "../../../../assets";
import { PreviewFile } from "../../../../models";
import { canPreviewFile, isMimeTypeImage } from "../../../../utils";
import { Divider, LoadingSpinner } from "../../../general";
import { FormLabel } from "../../FormLabel";
import { DragAndDropOrderBehavior, useMultipleFileUpload } from "./useMultipleFileUpload";

export interface MultipleFileUploadProps {
  onChange: (previewFiles: PreviewFile[]) => void;
  value?: PreviewFile[];
  onFileUpload?: (file: RcFile) => Promise<PreviewFile>;
  onPreviewFile?: (previewFile: PreviewFile) => void;
  title?: string;
  allowLabelDangerousHtml?: boolean;
  subTitle?: string;
  maxFileSize?: number;
  maxFileCount?: number;
  informationLabel?: string;
  accept?: string;
  orderBehavior?: DragAndDropOrderBehavior;
  isShowImageThumbnails?: boolean;
}
export const MultipleFileUpload = forwardRef(
  (
    {
      title = "Multiple file upload",
      allowLabelDangerousHtml,
      onFileUpload,
      subTitle,
      maxFileSize = 1,
      maxFileCount = 10,
      informationLabel,
      accept = "image/*",
      onChange,
      onPreviewFile,
      value,
      orderBehavior = "move",
      isShowImageThumbnails = true,
    }: MultipleFileUploadProps,
    forwardedRef
  ): JSX.Element => {
    const {
      id,
      previewFiles,
      onDragOverDropZone,
      uploadingFilesCount,
      setOnDragOverDropZone,
      onAction,
      onRemoveFile,
      onFileDrop,
      handleOnPreviewFile,
    } = useMultipleFileUpload({
      onChange,
      onFileUpload,
      onPreviewFile,
      value,
      orderBehavior,
      maxFileCount,
      maxFileSize,
      forwardedRef,
    });

    return (
      <div
        className={`MultipleFileUploadWrapper ${uploadingFilesCount > 0 ? "MultipleFileUploadWrapperDisabled" : ""}`}
      >
        <FormLabel htmlFor={id} label={title} allowDangerousHtml={allowLabelDangerousHtml} />
        {subTitle && <p className="body1">{subTitle}</p>}
        <Upload
          action={(action) => onAction(action)}
          multiple
          className={`MultipleFileUpload ${onDragOverDropZone ? "MultipleFileUploadContentDragOver" : ""}`}
          accept={accept}
          id={id}
          customRequest={() => undefined} // Removing default component request by providing an empty one
        >
          {uploadingFilesCount > 0 ? (
            <div className="MultipleFileUploadContent TextWithLoadingSpinner">
              <LoadingSpinner size="medium" type="dark" />
              <p>Uploading...</p>
            </div>
          ) : (
            <div
              className="MultipleFileUploadContent"
              onDragOver={() => setOnDragOverDropZone(true)}
              onDragLeave={() => setOnDragOverDropZone(false)}
            >
              <FilePlusBlueIcon />
              <p className="body1">Click or drag a file to this area to upload</p>
            </div>
          )}
        </Upload>
        <div className="body2 MultipleFileUploadInformation">
          {informationLabel ?? `Max file size ${maxFileSize}MB`}
        </div>
        {previewFiles.map((el) => (
          <div
            className={`MultipleFileUploadItem ${!el.file ? "MultipleFileUploadItemDefault" : ""}`}
            key={el.uuid}
            draggable
            onDrop={(ev) => {
              const draggedFileId = ev.dataTransfer.getData("fileId");
              onFileDrop(el.uuid, draggedFileId);
            }}
            onDragOver={(ev) => ev.preventDefault()}
            onDragStart={(ev) => ev.dataTransfer.setData("fileId", el.uuid)}
          >
            <div className="MultipleFileUploadItemFilename">
              <a href={el.url} target="_blank" rel="noreferrer">
                {isShowImageThumbnails && isMimeTypeImage(el.mimeType) ? (
                  <img alt={el.filename} src={el.url} width={24} height={24} />
                ) : (
                  <div>
                    <FileBlueIcon />
                  </div>
                )}
              </a>
              <p>{el.filename}</p>
            </div>
            <div className="MultipleFileUploadItemActions">
              {onPreviewFile && (
                <div className="MultipleFileUploadItemPreview">
                  <div
                    className="MultipleFileUploadItemPreviewBtn"
                    role="button"
                    onClick={() => onPreviewFile(el)}
                    onKeyDown={(e) => handleOnPreviewFile(e, el)}
                    tabIndex={0}
                  >
                    {canPreviewFile(el.mimeType) ? (
                      <EyeGreyIcon className="EyeGreyIcon" />
                    ) : (
                      <DownloadDarkIcon className="DownloadDarkIcon" />
                    )}
                  </div>
                  <Divider type="vertical" thickness={1} />
                </div>
              )}
              <button onClick={() => onRemoveFile(el)}>
                <TrashDark />
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  }
);

import { RcFile } from "rc-upload/lib/interface";
import React, { RefObject, useEffect, useId, useRef, useState } from "react";

import { PreviewFile } from "../../../../models";
import { useClickOutside, useTrapFocusWithinDiv } from "../../../../utils";
import { Toast } from "../../../general";

interface useSingleFileDropdownUploadReturnData {
  id: string;
  selectedFile: PreviewFile | undefined;
  reusableFiles: PreviewFile[];
  showDropdown: boolean;
  showDeleteFileModal: boolean;
  isFileUploading: boolean;
  dropdownRef: RefObject<HTMLDivElement>;
  trapFocus: (node: HTMLDivElement) => void;
  handleClick: () => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  handleKeyDownFile: (e: React.KeyboardEvent<HTMLDivElement>, pf: PreviewFile) => void;
  handleKeyDownOnUploader: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  handleOnDeleteUpload: () => void;
  handleKeyDownOnDeleteUpload: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  handleOnPreviewFile: (e: React.KeyboardEvent<HTMLDivElement>, pf: PreviewFile) => void;
  reuseFile: (file: PreviewFile) => void;
  onAction: (file: RcFile) => Promise<string>;
  onConfirmFileDeleteModal: () => void;
  onCloseFileDeleteModal: () => void;
}

interface useSingleFileDropdownUploadProps {
  value: PreviewFile | undefined;
  inputFiles: PreviewFile[];
  maxFileSize: number;
  onChange: (e: PreviewFile | undefined) => void;
  onFileUpload?: (e: RcFile, repeaterIndex?: number) => Promise<PreviewFile>;
  onPreviewFile?: (previewFile: PreviewFile) => void;
  shouldConfirmDeletion?: boolean;
  repeaterIndex?: number;
}

export const useSingleFileDropdownUpload = ({
  value,
  inputFiles,
  maxFileSize,
  onChange,
  onFileUpload,
  onPreviewFile,
  shouldConfirmDeletion,
  repeaterIndex,
}: useSingleFileDropdownUploadProps): useSingleFileDropdownUploadReturnData => {
  const id = useId();
  const [selectedFile, setSelectedFile] = useState<PreviewFile | undefined>(value);
  const [reusableFiles, setReusableFiles] = useState<PreviewFile[]>(inputFiles);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showDeleteFileModal, setShowDeleteFileModal] = useState<boolean>(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const trapFocus = useTrapFocusWithinDiv();

  // rc-uploader adds its own tabindex to a wrapper over our own button inside it
  // we need to remove the tab index from the wrapper to avoid double focusing
  useEffect(() => {
    // Make rc-upload untabbable
    const el = dropdownRef?.current?.querySelector(".rc-upload") as HTMLSpanElement;
    if (el) {
      el.tabIndex = -1;
    }
  }, [showDropdown]);

  useEffect(() => {
    setSelectedFile(value);
  }, [value]);

  useEffect(() => {
    setReusableFiles(inputFiles);
  }, [inputFiles]);

  const hideSelectorPopup = (): void => {
    setShowDropdown(false);

    // This will re-select the input box
    const dropdownInput = dropdownRef?.current?.querySelector(".SingleFileDropdownUploadInput") as HTMLDivElement;
    dropdownInput.focus();
  };

  const onAction = async (file: RcFile): Promise<string> => {
    setIsFileUploading(true);

    const fileSize = Number((file.size / 1024 / 1024).toFixed(4)); // MB

    if (fileSize > maxFileSize) {
      Toast.error({
        message: `Please select a file which is less than ${maxFileSize}MB`,
      });
      setIsFileUploading(false);

      return "";
    }

    let previewFile: PreviewFile = {
      file,
      mimeType: file.type,
      url: URL.createObjectURL(file),
      filename: file.type,
      uuid: file.uid,
    };

    // if on onFileUpload method is passed we await for the files to be uploaded.
    if (onFileUpload) {
      try {
        previewFile = await onFileUpload(file, repeaterIndex);
      } catch (error) {
        Toast.error({
          message: (error as string) || `Failed to upload ${file.name}`,
        });
        setIsFileUploading(false);

        return "";
      }
    }

    setSelectedFile(previewFile);
    onChange(previewFile);
    setReusableFiles((prevFiles) => [...prevFiles, previewFile]);
    hideSelectorPopup();
    setIsFileUploading(false);

    return "";
  };

  const reuseFile = (previewFile: PreviewFile): void => {
    setSelectedFile(previewFile);
    onChange(previewFile);
    hideSelectorPopup();
  };

  const handleClick = (): void => {
    setShowDropdown(!showDropdown);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    switch (e.key) {
      case "Enter":
        setShowDropdown(!showDropdown);
        break;
      case "Escape":
        hideSelectorPopup();
        break;
      default:
        break;
    }
  };

  const handleKeyDownFile = (e: React.KeyboardEvent<HTMLDivElement>, pf: PreviewFile): void => {
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        reuseFile(pf);
        hideSelectorPopup();
        break;
      case "Escape":
        hideSelectorPopup();
        break;
      default:
        break;
    }
  };

  const handleKeyDownOnUploader = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    switch (e.key) {
      case "Escape":
        hideSelectorPopup();
        break;
      default:
        break;
    }
  };

  const handleOnPreviewFile = (e: React.KeyboardEvent<HTMLDivElement>, pf: PreviewFile): void => {
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        if (onPreviewFile) {
          onPreviewFile(pf);
        }
        hideSelectorPopup();
        break;
      case "Escape":
        hideSelectorPopup();
        break;
      default:
        break;
    }
  };

  const deleteUpload = (): void => {
    setSelectedFile(undefined);
    setReusableFiles((prevFiles) => prevFiles.filter((el) => el.uuid !== selectedFile?.uuid));
    onChange(undefined);
    hideSelectorPopup();
  };

  const handleOnDeleteUpload = (): void => {
    if (shouldConfirmDeletion) {
      setShowDeleteFileModal(true);
    } else {
      deleteUpload();
    }
  };

  const handleKeyDownOnDeleteUpload = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        handleOnDeleteUpload();
        hideSelectorPopup();
        break;
      case "Escape":
        hideSelectorPopup();
        break;
      default:
        break;
    }
  };

  const onCloseFileDeleteModal = (): void => {
    setShowDeleteFileModal(false);
  };

  const onConfirmFileDeleteModal = (): void => {
    deleteUpload();
    setShowDeleteFileModal(false);
  };

  useClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  // Cleanup the created object Urls
  useEffect(() => {
    return () => {
      inputFiles.map((el) => URL.revokeObjectURL(el.url));
    };
  }, []);

  return {
    id,
    selectedFile,
    reusableFiles,
    showDropdown,
    showDeleteFileModal,
    isFileUploading,
    dropdownRef,
    trapFocus,
    handleClick,
    handleKeyDown,
    handleKeyDownFile,
    handleKeyDownOnUploader,
    handleOnDeleteUpload,
    handleKeyDownOnDeleteUpload,
    handleOnPreviewFile,
    reuseFile,
    onAction,
    onConfirmFileDeleteModal,
    onCloseFileDeleteModal,
  };
};

import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { HeaderRendererProps, SortColumn as RdgSortColumn } from "react-data-grid";

import { FiltersDarkIcon, SortArrowsDarkIcon } from "../../../../assets/icons";
import { FilterCriteria, FilterOperator } from "../../../../models";
import { logError } from "../../../../service/error";
import { ResultData } from "../../../../service/Shared";
import { FilterContext } from "../context";
import { ColumnDefinition, HeaderColumnSelectOption } from "../models";
import { FiltersSelect } from "./HeaderColumnSelect/FiltersSelect";
import { SortingSelect } from "./HeaderColumnSelect/SortingSelect";
import { useHeaderRenderer } from "./useHeaderRenderer";

const DEFAULT_FILTER_ICON_VALUE = 1;
const DEFAULT_SORT_DIRECTION = "ASC";

export type onFilterChangedEvent = (
  columnKey: string,
  operator: FilterOperator,
  value: string,
  filterId: number | undefined
) => void;

interface CustomHeaderRendererProps {
  columnDefinition: ColumnDefinition;
  onFilterChanged: onFilterChangedEvent;
  dataGridFilterable: boolean;
  dataGridSortable: boolean;
  sortColumns: readonly RdgSortColumn[];
  setSortColumns: Dispatch<SetStateAction<readonly RdgSortColumn[]>>;
  filtersEnabled: boolean;
  filterCriteria: FilterCriteria[];
  setFilterCriteria: Dispatch<SetStateAction<FilterCriteria[]>>;
}

export const HeaderRenderer = <R, SR>({
  columnDefinition,
  onFilterChanged,
  dataGridFilterable,
  dataGridSortable,
  sortColumns,
  setSortColumns,
  filtersEnabled,
  filterCriteria,
  setFilterCriteria,
}: CustomHeaderRendererProps): ((props2: HeaderRendererProps<ResultData, void>) => React.ReactElement) => {
  const {
    stringFilterMenuOptionsList,
    numberOrDateFilterMenuOptionsList,
    sortingMenuOptionsList,
    setSortingMenuOptionsList,
    sortingSelectRef,
    filteringSelectRef,
    openSortingMenu,
    openFilteringMenu,
    getSelectedFilterIcon,
    isColumnFilterable,
    isColumnSortable,
  } = useHeaderRenderer(columnDefinition, dataGridFilterable, dataGridSortable, filterCriteria, sortColumns);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return ({ column }: HeaderRendererProps<R, SR>) => {
    const filters = useContext(FilterContext);

    if (filters === undefined) throw new Error("FilterContext not set; check parent DataGrid component.");
    const [filterValue, setFilterValue] = useState(
      filters.find((f) => f.key === columnDefinition.key)?.value?.toString() || ""
    );

    const isColumnFiltered = (): boolean => {
      return !!filterCriteria.find((fc) => fc.key === column.key);
    };

    const getDefaultFilterOperator = (): FilterOperator => {
      switch (columnDefinition.dataType) {
        case "string":
          return "contains";
        case "number":
        case "Date":
          return "eq";
        default:
          logError({
            error: `In 'HeaderRenderer.tsx', The following dataType is not valid: ${columnDefinition.dataType}`,
          });
          return "eq";
      }
    };

    const handleFilterValueInput = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
        event.stopPropagation();
      }

      if (event.key === "Enter") {
        const appliedFilter = filterCriteria.find((fc) => fc.key === column.key);

        onFilterChanged(
          column.key,
          appliedFilter?.operator || getDefaultFilterOperator(),
          filterValue,
          appliedFilter?.filterId || DEFAULT_FILTER_ICON_VALUE
        );
      }
    };

    const addFilterToEmptyStateArray = (el: HeaderColumnSelectOption): void => {
      if (stringFilterMenuOptionsList.at(-1)?.id === el.id) {
        // If reset button was pressed when there are no other filters applied, do not add a filter criteria
        return;
      }

      setFilterCriteria([
        {
          key: column.key,
          value: "",
          operator: el.filterOperatorValue || getDefaultFilterOperator(),
          filterId: el.id,
        },
      ]);
    };

    const addFilterToNonEmptyStateArray = (el: HeaderColumnSelectOption, updatedFilters: FilterCriteria[]): void => {
      updatedFilters.push({
        key: column.key,
        value: "",
        operator: el.filterOperatorValue || getDefaultFilterOperator(),
        filterId: el.id,
      });

      setFilterCriteria(updatedFilters);
    };

    const removeFilterFromStateArray = (updatedFilters: FilterCriteria[]): void => {
      setFilterCriteria(updatedFilters.filter((fc) => fc.key !== column.key));
    };

    const updateFilterFromStateArray = (el: HeaderColumnSelectOption, updatedFilters: FilterCriteria[]): void => {
      // eslint-disable-next-line no-param-reassign
      updatedFilters = filterCriteria.map((fc) => {
        if (fc.key === column.key) {
          return {
            key: fc.key,
            value: fc.value,
            operator: el.filterOperatorValue || getDefaultFilterOperator(),
            filterId: el.id,
          };
        }
        return fc;
      });

      setFilterCriteria(updatedFilters);
    };

    const isColumnSorted = (): boolean => {
      return !!sortColumns.find((sc) => sc.columnKey === column.key);
    };

    const addSortColumnToStateArray = (updatedSortColumns: RdgSortColumn[], el: HeaderColumnSelectOption): void => {
      updatedSortColumns.push({
        columnKey: column.key,
        direction: el.sortDirection || DEFAULT_SORT_DIRECTION,
      });

      setSortColumns(updatedSortColumns);

      const updatedSortingMenuOptionsList = sortingMenuOptionsList.map((sm) => {
        if (sm.sortDirection === el.sortDirection) {
          return {
            ...sm,
            isHidden: true,
          };
        }

        if (sm.sortDirection === undefined) {
          return {
            ...sm,
            isHidden: false,
          };
        }

        return sm;
      });

      setSortingMenuOptionsList(updatedSortingMenuOptionsList);
    };

    const updateSortColumnFromStateArray = (
      updatedSortColumns: RdgSortColumn[],
      el: HeaderColumnSelectOption
    ): void => {
      // eslint-disable-next-line no-param-reassign
      updatedSortColumns = updatedSortColumns.map((sc) => {
        if (sc.columnKey === column.key) {
          return {
            columnKey: column.key,
            direction: el.sortDirection || DEFAULT_SORT_DIRECTION,
          };
        }

        return {
          columnKey: sc.columnKey,
          direction: sc.direction,
        };
      });

      setSortColumns(updatedSortColumns);

      const updatedSortingMenuOptionsList = sortingMenuOptionsList.map((sm) => {
        if (sm.sortDirection === undefined) {
          return sm;
        }

        if (sm.sortDirection !== el.sortDirection) {
          return {
            ...sm,
            isHidden: false,
          };
        }

        return {
          ...sm,
          isHidden: true,
        };
      });

      setSortingMenuOptionsList(updatedSortingMenuOptionsList);
    };

    const removeSortColumnFromStateArray = (): void => {
      setSortColumns([]);

      const updatedSortingMenuOptionsList = sortingMenuOptionsList.map((sm) => {
        if (sm.sortDirection === undefined) {
          return {
            ...sm,
            isHidden: true,
          };
        }

        return {
          ...sm,
          isHidden: false,
        };
      });

      setSortingMenuOptionsList(updatedSortingMenuOptionsList);
    };

    const onSortingMenuItemClick = (el: HeaderColumnSelectOption): void => {
      let updatedSortColumns = [...sortColumns];

      if (updatedSortColumns.find((sc) => sc.columnKey !== column.key)) {
        // Used to remove sorting for other columns (never let more than two elements in the array)
        updatedSortColumns = [];
      }

      if (el.id === sortingMenuOptionsList.at(-1)?.id) {
        removeSortColumnFromStateArray();
      } else if (!sortColumns.find((sc) => sc.columnKey === column.key)) {
        addSortColumnToStateArray(updatedSortColumns, el);
      } else {
        updateSortColumnFromStateArray(updatedSortColumns, el);
      }
    };

    const onFilterMenuItemClick = (el: HeaderColumnSelectOption): void => {
      if (filterCriteria.length === 0) {
        addFilterToEmptyStateArray(el);
      } else {
        const updatedFilters = [...filterCriteria];

        if (stringFilterMenuOptionsList.at(-1)?.id === el.id) {
          removeFilterFromStateArray(updatedFilters);
        } else {
          const filterToUpdate = filterCriteria.find((fc) => fc.key === column.key);

          if (filterToUpdate === undefined) {
            addFilterToNonEmptyStateArray(el, updatedFilters);
          } else {
            updateFilterFromStateArray(el, updatedFilters);
          }
        }
      }
    };

    return (
      <>
        <div
          role="button"
          tabIndex={0}
          className={`DataGridHeaderColumnName ${columnDefinition.alignment || ""} ${
            isColumnSortable ? "DataGridHeaderColumnName_sortable" : ""
          }`}
          title={isColumnSortable ? "Sort by" : ""}
          onClick={openSortingMenu}
          onKeyDown={openSortingMenu}
        >
          <span>{column.name}</span>
          {isColumnSortable && (
            <>
              <SortingSelect
                ref={sortingSelectRef}
                sortingMenuOptionsList={sortingMenuOptionsList}
                onOptionClick={onSortingMenuItemClick}
              />
              <div className={`${isColumnSorted() ? "DataGridHeaderColumnName_SortedColumnIconContainer" : ""}`}>
                {sortingMenuOptionsList.find((sm) => sm.isHidden && sm.sortDirection !== undefined)?.icon}
              </div>
              <div className={`DataGridHeaderColumnName_SortIconContainer ${isColumnSorted() ? "ColumnSorted" : ""}`}>
                <SortArrowsDarkIcon />
              </div>
            </>
          )}
        </div>
        {isColumnFilterable && filtersEnabled && (
          <div className="DataGridHeaderFilterContainer" id="HeaderFilterIcon">
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                openFilteringMenu();
              }}
              onKeyDown={() => {
                openFilteringMenu();
              }}
              className="DataGridHeaderFilterIconContainer"
            >
              {!filterCriteria?.find((fc) => fc.key === column.key) ? (
                <FiltersDarkIcon className="DataGridHeaderFilterIcon" />
              ) : (
                getSelectedFilterIcon(
                  columnDefinition.dataType,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  filterCriteria?.find((fc) => fc.key === column.key)!.filterId || DEFAULT_FILTER_ICON_VALUE
                )
              )}
              {columnDefinition.dataType === "string" && (
                <FiltersSelect
                  ref={filteringSelectRef}
                  filterMenuOptionsList={stringFilterMenuOptionsList}
                  onOptionClick={onFilterMenuItemClick}
                />
              )}
              {(columnDefinition.dataType === "number" || columnDefinition.dataType === "Date") && (
                <FiltersSelect
                  ref={filteringSelectRef}
                  filterMenuOptionsList={numberOrDateFilterMenuOptionsList}
                  onOptionClick={onFilterMenuItemClick}
                />
              )}
            </div>
            <input
              key={`DataGridHeaderFilter_${column.key}`}
              readOnly={!isColumnFiltered()}
              placeholder="filter"
              value={filterValue}
              tabIndex={0}
              onClick={() => {
                if (!isColumnFiltered()) {
                  openFilteringMenu();
                }
              }}
              onChange={(e) => {
                setFilterValue(e.target.value);
                // Checking if the table is already filtered by this key, if it is and the filter has been cleared then we update
                const isFiltered = filters.some((el) => el.key === column.key);

                if (e.target.value === "" && isFiltered) {
                  const currentAppliedFilter = filters.find((el) => el.key === column.key);

                  onFilterChanged(
                    column.key,
                    currentAppliedFilter?.operator || getDefaultFilterOperator(),
                    e.target.value,
                    currentAppliedFilter?.filterId
                  );
                }
              }}
              onKeyDown={handleFilterValueInput}
              className="DataGridHeaderFilter"
            />
          </div>
        )}
      </>
    );
  };
};

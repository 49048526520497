import { Dispatch, MutableRefObject, ReactNode, SetStateAction, useEffect, useRef, useState } from "react";
import { SortColumn as RdgSortColumn } from "react-data-grid";

import {
  ContainsFilterDark,
  EndsWithFilterDarkIcon,
  EqualsFilterDarkIcon,
  FiltersDarkIcon,
  GreaterThanFilterDarkIcon,
  GreaterThanOrEqualFilterDarkIcon,
  LessThanFilterDarkIcon,
  LessThanOrEqualFilterDarkIcon,
  NotContainsFilterDark,
  NotEqualsFilterDarkIcon,
  ResetDarkIcon,
  SortArrowAscendingDarkIcon,
  SortArrowDescendingDarkIcon,
  StartsWithFilterDarkIcon,
} from "../../../../assets";
import { FilterCriteria, SupportedDataTypeNames } from "../../../../models";
import { logError } from "../../../../service/error";
import { ColumnDefinition, HeaderColumnSelectOption } from "../models";

const initialSortingMenuOptionsList: HeaderColumnSelectOption[] = [
  {
    id: 1,
    icon: <SortArrowDescendingDarkIcon />,
    sortDirection: "DESC",
    value: "Descending",
    isHidden: false,
  },
  {
    id: 2,
    icon: <SortArrowAscendingDarkIcon />,
    sortDirection: "ASC",
    value: "Ascending",
    isHidden: false,
  },
  {
    id: 3,
    icon: <ResetDarkIcon />,
    value: "Reset",
    isHidden: true,
  },
];

const stringFilterMenuOptionsList: HeaderColumnSelectOption[] = [
  {
    id: 1,
    icon: <ContainsFilterDark />,
    value: "Contains",
    filterOperatorValue: "contains",
  },
  {
    id: 2,
    icon: <NotContainsFilterDark />,
    value: "Does not contain",
    filterOperatorValue: "ncontains",
  },
  {
    id: 3,
    icon: <StartsWithFilterDarkIcon />,
    value: "Starts with",
    filterOperatorValue: "startsWith",
  },
  {
    id: 4,
    icon: <EndsWithFilterDarkIcon />,
    value: "Ends with",
    filterOperatorValue: "endsWith",
  },
  {
    id: 5,
    icon: <EqualsFilterDarkIcon />,
    value: "Equals",
    filterOperatorValue: "eq",
  },
  {
    id: 6,
    icon: <NotEqualsFilterDarkIcon />,
    value: "Does not equal",
    filterOperatorValue: "neq",
  },
  {
    id: 7,
    icon: <ResetDarkIcon />,
    value: "Reset",
  },
];

const numberOrDateFilterMenuOptionsList: HeaderColumnSelectOption[] = [
  {
    id: 1,
    icon: <EqualsFilterDarkIcon />,
    value: "Equals",
    filterOperatorValue: "eq",
  },
  {
    id: 2,
    icon: <NotEqualsFilterDarkIcon />,
    value: "Does not equal",
    filterOperatorValue: "neq",
  },
  {
    id: 3,
    icon: <LessThanFilterDarkIcon />,
    value: "Less than",
    filterOperatorValue: "lt",
  },
  {
    id: 4,
    icon: <GreaterThanFilterDarkIcon />,
    value: "Greater than",
    filterOperatorValue: "gt",
  },
  {
    id: 5,
    icon: <LessThanOrEqualFilterDarkIcon />,
    value: "Less than or equal to",
    filterOperatorValue: "lte",
  },
  {
    id: 6,
    icon: <GreaterThanOrEqualFilterDarkIcon />,
    value: "Greater than or equal to",
    filterOperatorValue: "gte",
  },
  {
    id: 7,
    icon: <ResetDarkIcon />,
    value: "Reset",
  },
];

interface UseHeaderRendererReturnData {
  stringFilterMenuOptionsList: HeaderColumnSelectOption[];
  numberOrDateFilterMenuOptionsList: HeaderColumnSelectOption[];
  sortingMenuOptionsList: HeaderColumnSelectOption[];
  setSortingMenuOptionsList: Dispatch<SetStateAction<HeaderColumnSelectOption[]>>;
  sortingSelectRef: MutableRefObject<null>;
  filteringSelectRef: MutableRefObject<null>;
  openSortingMenu: () => void;
  openFilteringMenu: () => void;
  getSelectedFilterIcon: (columnDataType: SupportedDataTypeNames, id: number) => ReactNode;
  isColumnFilterable: boolean;
  isColumnSortable: boolean;
}

export const useHeaderRenderer = (
  columnDefinition: ColumnDefinition,
  dataGridFilterable: boolean,
  dataGridSortable: boolean,
  filterCriteria: FilterCriteria[],
  sortColumns: readonly RdgSortColumn[]
): UseHeaderRendererReturnData => {
  const [sortingMenuOptionsList, setSortingMenuOptionsList] =
    useState<HeaderColumnSelectOption[]>(initialSortingMenuOptionsList);

  const sortingSelectRef = useRef(null);

  const filteringSelectRef = useRef(null);

  useEffect(() => {
    // Used in order to reset the options list when another column is being sorted
    if (
      (Array.isArray(sortColumns) && sortColumns.length === 0) ||
      sortColumns.find((sc) => sc.columnKey !== columnDefinition.key)
    ) {
      setSortingMenuOptionsList(initialSortingMenuOptionsList);
    }
  }, [sortColumns]);

  useEffect(() => {
    // Used to remove the default sorted option from the sortingMenuOptionsList
    const defaultSortedColumn = sortColumns.find((sc) => sc.columnKey === columnDefinition.key);

    if (defaultSortedColumn) {
      const updatedSortingMenuOptionsList = sortingMenuOptionsList.map((sm) => {
        if (sm.sortDirection === undefined) {
          return { ...sm, isHidden: false };
        }

        if (sm.sortDirection !== defaultSortedColumn.direction) {
          return { ...sm, isHidden: false };
        }

        return { ...sm, isHidden: true };
      });

      setSortingMenuOptionsList(updatedSortingMenuOptionsList);
    }
  }, []);

  const openSortingMenu = (): void => {
    if (sortingSelectRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sortingSelectRef.current.focus();
    }
  };

  const openFilteringMenu = (): void => {
    if (filteringSelectRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filteringSelectRef.current.focus();
    }
  };

  const getSelectedFilterIcon = (columnDataType: SupportedDataTypeNames, id: number): ReactNode => {
    switch (columnDataType) {
      case "string": {
        if (id === stringFilterMenuOptionsList.at(-1)?.id) {
          return <FiltersDarkIcon className="DataGridHeaderFilterIcon" />;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return stringFilterMenuOptionsList.find((f) => f.id === id)!.icon;
      }
      case "number":
      case "Date": {
        if (id === numberOrDateFilterMenuOptionsList.at(-1)?.id) {
          return <FiltersDarkIcon className="DataGridHeaderFilterIcon" />;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return numberOrDateFilterMenuOptionsList.find((f) => f.id === id)!.icon;
      }
      default:
        logError({
          error: `In 'useHeaderRenderer.tsx', The following dataType is not valid: ${columnDefinition.dataType}`,
        });
        return <FiltersDarkIcon className="DataGridHeaderFilterIcon" />;
    }
  };

  const isColumnFilterable =
    (dataGridFilterable === null ? true : dataGridFilterable) &&
    (columnDefinition.filterable === null || columnDefinition.filterable === undefined
      ? true
      : columnDefinition.filterable);

  const isColumnSortable =
    (dataGridSortable === null ? true : dataGridSortable) &&
    (columnDefinition.sortable === null || columnDefinition.sortable === undefined ? true : columnDefinition.sortable);

  return {
    stringFilterMenuOptionsList,
    numberOrDateFilterMenuOptionsList,
    sortingMenuOptionsList,
    sortingSelectRef,
    filteringSelectRef,
    openSortingMenu,
    openFilteringMenu,
    setSortingMenuOptionsList,
    getSelectedFilterIcon,
    isColumnFilterable,
    isColumnSortable,
  };
};
